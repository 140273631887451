import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Privacy Policy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="About KayakFishingMan.com"/>
        <p>Discover the world of kayak fishing!</p>
        <p>Just you, the water and fish! Does this sound perfect to you?</p>
        <p>It does to us!</p>
        <p>That's why we’ve devoted our time to bringing you the best information about kayak fishing!</p>
        <p>From detailed reviews of kayaks, fish finders and more, to in depth guides, tips, tricks and advice!</p>
        <Divider />
      </Main>
    </Stack>
  </>
)
